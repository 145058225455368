<script lang="ts">
  import { onMount } from "svelte";
  import { queryClient } from "@src/GraphQL/query";

  import Bar from "svelte-chartjs/src/Bar.svelte";
  import HorizontalBar from "svelte-chartjs/src/HorizontalBar.svelte";
  import Pie from "svelte-chartjs/src/Pie.svelte";
  import Polar from "svelte-chartjs/src/Polar.svelte";
  import Line from "svelte-chartjs/src/Line.svelte"
  import graphql2chartjs from "graphql2chartjs";
  import palette from "google-palette";
  import Loader from "../General/Loader.svelte"

  export let options;

  const gridSize = options.gridSize ?? 2;
  let loading = true;
  let chartsUI = [];

  const hexToRgb = (hex, a) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${a})`;
  };

  onMount(async () => {
    for (let chart of options.charts) {
      let data = (await queryClient({ query: `${chart.query} ` })).data;
      //get all data sets above,
      //loop the chartsUI.push below
      let colorsLength = 0;
      const datasetLength = Object.values(data).length;
      if(datasetLength > 1){
        colorsLength = 7;
      }else if((Object.values(data)[0] as Array<any>).length > 7){
        colorsLength = 7
      }else{
        colorsLength = (Object.values(data)[0] as Array<any>).length;
      }
      
      let colors = palette("tol", colorsLength);
      
      let i = 0;
      let colorForDataset = chart?.colorForDataset ?? {}

      //need to check if its multiple datasets or one dataset. do colors differently
      let gchartData = new graphql2chartjs(data, (datasetName, dataPoint) => {
        let color;
        if(i > 6) i = 0;
        let c = i;
        i = i + 1;
        if(datasetLength > 1){
          //get a different color for each different datasetName
          if(!colorForDataset[datasetName]) colorForDataset[datasetName] = colors[Object.values(colorForDataset).length]
          color = colorForDataset[datasetName]
        }else{
          color = colors[c]
        }
        return {
          chartType: chart.type == "horizontalBar" ? "bar" : chart.type,
          backgroundColor: hexToRgb(color,0.3),
          hoverBackgroundColor: hexToRgb(color,0.4),
          borderWidth: 2,
          borderColor: hexToRgb(color,0.95),
          hoverBorderColor: hexToRgb(color,1),
        };
      }).data;
      

      let options = {
        responsive: true,
      };
      if (chart.type == "bar" || chart.type == "horizontalBar") {
        options["scales"] = {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        };
      }

      chartsUI.push({
        name: chart.name,
        type: chart.type,
        data: gchartData,
        options,
      });
    }

    loading = false;
  });
</script>

{#if !loading}
  <div class="grid grid-cols-1 md:grid-cols-{gridSize} gap-5 mb-5">
    {#each chartsUI as chart}
      <div class="bg-white dark:bg-gray-800  overflow-hidden rounded-lg border border-gray-400 dark:border-gray-900">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-500 dark:text-gray-200 font-bold">{chart.name}</h3>
        </div>
        <div class="bg-white dark:bg-gray-800  px-4 py-3">
          {#if chart.type == 'bar'}
            <Bar data={chart.data} options={chart.options} />
          {:else if chart.type == 'horizontalBar'}
            <HorizontalBar data={chart.data} options={chart.options} />
          {:else if chart.type == 'pie'}
            <Pie data={chart.data} options={chart.options} />
          {:else if chart.type == 'polarArea'}
            <Polar data={chart.data} options={chart.options} />
          {:else if chart.type == 'line'}
            <Line data={chart.data} options={chart.options} />
          {/if}
        </div>
      </div>
    {:else}
      <h3 class="py-4 pl-4">No Charts Found</h3>
    {/each}
  </div>
{:else}
  <div class="grid grid-cols-1 md:grid-cols-{gridSize} gap-5 mb-5">
    {#each options.charts as chart}
      <div class="bg-white dark:bg-gray-800  overflow-hidden rounded-lg border border-gray-400 dark:border-gray-900">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-500 dark:text-gray-200 font-bold">Chart Loading</h3>
        </div>
        <div class="bg-white dark:bg-gray-800  px-4 py-3">
          <Loader/>
        </div>
      </div>
    {:else}
      <h3 class="py-4 pl-4">No Charts Found</h3>
    {/each}
  </div>
{/if}
