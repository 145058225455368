<script lang="ts">
  import type { Writable } from "svelte/store";
  import { routeContext, systemContext } from "../stores";

  import { QueryStore } from "../GraphQL/query";
  import { stringSepToNorm } from "../random";
  import { Provider } from "../provider";

  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let options;
  export let useContext = "systemContext";

  const contextStore: Writable<any> = useContext == "systemContext" ? systemContext : useContext == "routeContext" ? routeContext : null;
  let fieldInContext = {};

  let queriedOptions = options?.options ?? [];
  let open = false;

  $: fieldInContext = $contextStore[options?.field] ?? {};

  const selectOption = (event, option) => {
    event.stopPropagation();

    if (useContext == "systemContext") {
      const [, , authSender] = Provider.of("authState", self);
      const sendContexts = (systemContext) => authSender.child["changecontexts"]({ changingContext: options?.field, systemContext });

      switch (options?.field) {
        case "organization":
          sendContexts({ organization: option });
          break;
        case "system":
          sendContexts({ organization: $contextStore.organization, system: option });
          break;
        default:
          sendContexts({ ...$contextStore, [options?.field]: option });
          break;
      }
    } else {
      $contextStore[options?.field] = option;
    }

    open = false;
  };

  let self;

  $: if (queryDataStore) queriedOptions = $queryDataStore?.data?.data ?? [];
  let queryDataStore;

  if (options.optionsQuery) {
    const queryStore = new QueryStore({ query: options?.optionsQuery });
    queryDataStore = queryStore.getDataStore();
  }

  $: if (Object.keys(fieldInContext).length > 0) {
    dispatch("fieldhasvalue", options?.field);
  }
</script>

<div bind:this={self} class="bg-white dark:bg-gray-800 mb-5 align-middle inline-block min-w-full overflow-visible rounded-lg">
  <div class="bg-white dark:bg-gray-800  rounded-t-lg">
    <div class="flex items-center justify-between flex-wrap sm:flex-no-wrap">
      <div>
        <h3 class="text-xs leading-5 text-gray-500 dark:text-gray-200 font-bold">{stringSepToNorm(options?.field)}</h3>
      </div>
      <div class="flex-shrink-0"><span class="inline-flex rounded-md space-x-2" /></div>
    </div>
  </div>
  <div class="bg-white dark:bg-gray-800 rounded-b-lg">
    <div class="flex items-center justify-between flex-wrap sm:flex-no-wrap">
      <div class="w-full">
        <div class="flex flex-row space-x-2 w-full">
          <div class="mt-1 w-full flex rounded-md shadow-sm">
            <div class="relative h-full w-full flex-grow focus-within:z-10">
              <button
                on:click={() => (open = !open)}
                style="padding: 0"
                class="border border-gray-200 dark:border-gray-400 dark:bg-gray-800 p-0 block w-full h-full text-xs sm:leading-5 transition ease-in-out duration-150"
              >
                <div class="flex flex-col w-full">
                  <div class="small-box  justify-between px-2">
                    <div class="flex flex-row w-full">
                      {#if fieldInContext?.image}
                        <div class="flex"><img alt="System Logo" src={fieldInContext.image} /></div>
                      {/if}
                      <div class="flex flex-col truncate whitespace-no-wrap">
                        <span class="my-auto text-xs leading-6 font-medium">{fieldInContext?.label ?? 'Not Set'}</span>
                      </div>
                    </div>
                    <div class="flex flex-col text-gray-400">
                      <svg class="h-5 w-5 my-auto" viewBox="0 0 20 20" fill="none" stroke="currentColor"><path
                          d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  {#if open}
                    <div class="contexts border-t border-gray-200 dark:border-gray-400 max-h-40 customscrollbar">
                      {#each queriedOptions ?? [] as option}
                        {#if fieldInContext?.label != option.label}
                          <button on:click={(ev) => selectOption(ev, option)} class="small-box hover:bg-secondary-50 dark:hover:bg-secondary-700 px-2 focus:outline-none">
                            <div class="flex flex-row my-auto w-full ">
                              {#if option.image}
                                <div class="flex"><img alt="System Logo" src={option.image} /></div>
                              {/if}
                              <div class="flex flex-col truncate whitespace-no-wrap">
                                <span class="my-auto text-md leading-6 font-medium">{option.label}</span>
                              </div>
                            </div>
                          </button>
                        {/if}
                      {:else}
                        <div class="small-box px-2">
                          <div class="flex flex-row w-full">
                            <div class="flex flex-col truncate whitespace-no-wrap">
                              <span class="my-auto text-md leading-6 font-medium">No options found</span>
                            </div>
                          </div>
                        </div>
                      {/each}
                    </div>
                  {/if}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .small-box > div > div + div {
    margin-left: 15px;
  }

  .small-box {
    @apply h-8 flex flex-row w-full;
  }

  :global(.dark .contexts.customscrollbar) {
    --scrollbarBG: #1c1c20;
  }

  img {
    width: 33px;
    height: 33px;
    margin-top: auto;
    margin-bottom: auto;
  }
</style>
