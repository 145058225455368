<script>
  import { zip } from "svelte-pipeable-store";
  import { setUpper } from "../random";
  import { rx, matchState } from "../rxfsm";
  import { routeHashObs, routeContext } from "../stores";
  import { Provider } from "../provider";

  import HelpFab from "../General/HelpFab.svelte";
  import Loader from "../General/Loader.svelte";
  import Nav from "../General/Nav/Nav.svelte";

  import EmptySlot from "../General/EmptySlot.svelte";
  import Tabs from "../General/Tabs.svelte";

  import jsonfunctions, { parseJSONFunction } from "../jsonfunctions";
  import auth from "../auth";

  let self, loggedInState, authStateMain, authContext, uiConfigObs, currentRouteJSON, otherComponentOptions;
  $: if (self) [authStateMain, authContext] = Provider.of("authState", self);
  $: if (authStateMain) loggedInState = authStateMain.pipe(rx.pluck("child"), rx.pluck("current"));
  $: if (authStateMain)
    uiConfigObs = authContext.pipe(
      rx.pluck("systemExtendedContext"),
      rx.pluck("ui"),
      rx.filter((val) => val != null),
    );
  $: if (authStateMain)
    currentRouteJSON = zip([routeHashObs, uiConfigObs.pipe(rx.pluck("routes"))]).pipe(
      rx.filter((vals) => vals.length === 2),
      rx.map(getRouteJSON),
      rx.tap(([_, newRouteContextObjs]) => {
        routeContext.update((cur) => ({ ...cur, ...newRouteContextObjs }));
      }),
      rx.pluck(0),
    );
  $: if (uiConfigObs)
    otherComponentOptions = zip({
      functions: uiConfigObs.pipe(
        rx.pluck("functions"),
        rx.map((val) => {
          return Object.fromEntries(
            Object.entries({ ...jsonfunctions, ...val }).map(([name, json]) => {
              return [name, parseJSONFunction(name, json)];
            }),
          );
        }),
      ),
    });

  const userName = auth.userState.pipe(rx.pluck("displayName"));
  const userRoles = auth.claims.pipe(rx.pluck("https://hasura.io/jwt/claims"), rx.pluck("x-hasura-allowed-roles"));

  function getRouteJSON([routePath, source, showTabs = false, newRouteContextObjs = {}]) {
    const splitRoutePath = routePath == "" || routePath == "/" ? ["index"] : routePath.split("/");

    let _newObjsForRouteContext = { ...newRouteContextObjs };

    const foundRoute = source.find((en) => {
      if (source.length == 1 && en.name.match(/\[(.*?)\]/g)) {
        const routeVarName = en.name.substring(1, en.name.length - 1);
        const splitRouteVarName = routeVarName.split("_");

        splitRouteVarName.push(splitRoutePath[0]);
        splitRouteVarName.shift();

        _newObjsForRouteContext = {
          ...splitRouteVarName.reduceRight((a, cv) => ({ [cv]: a })),
          ..._newObjsForRouteContext,
        };

        return true;
      }

      return en.name == splitRoutePath[0];
    });

    if (foundRoute?.subroutes) return getRouteJSON([splitRoutePath.slice(1).join("/"), foundRoute.subroutes, false, _newObjsForRouteContext]);
    if (foundRoute?.tabroutes) return getRouteJSON([splitRoutePath.slice(1).join("/"), foundRoute.tabroutes, true, _newObjsForRouteContext]);

    return [{ ...foundRoute, showTabs }, _newObjsForRouteContext];
  }

  async function importComponent(component) {
    const Component = await import(`../Components/${component}.svelte`);

    return await Component.default;
  }
</script>

<div bind:this={self}>
  {#if matchState($loggedInState, 'identifyingcontexts', 'identifyingui')}
    <div class="min-h-screen bg-unit-secondary-500 text-secondary-800 flex flex-col justify-center w-full py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-12 w-auto" src="img/logo.svg" alt="Logo" />
        <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
          {#if $authContext.changingContext}Changing {setUpper($authContext.changingContext)}...{:else}Hello {$userName}{/if}
        </h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md mx-8">
        <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
          <Loader />
        </div>
      </div>
    </div>
  {:else if $loggedInState === 'uiready'}
    <Nav config={$uiConfigObs} logo={$authContext.systemExtendedContext.logo} currentRoutePath={$routeHashObs} />
    <main class="lg:px-16 px-4 lg:pt-5 pt-4 box-border">
      {#key $routeHashObs}
        {#if $currentRouteJSON['roles'] ? auth.helper.checkRoles($userRoles, $currentRouteJSON['roles']) : true}
          <svelte:component this={$currentRouteJSON['showTabs'] ? Tabs : EmptySlot} config={$uiConfigObs} currentRoutePath={$routeHashObs}>
            <div>
              {#each $currentRouteJSON?.body ?? [] as component}
                {#if component['roles'] ? auth.helper.checkRoles($userRoles, component['roles']) : true}
                  {#await importComponent(component['item']) then importedComponent}
                    <svelte:component this={importedComponent} options={component['options']} {...$otherComponentOptions} />
                  {/await}
                {/if}
              {/each}
            </div>
          </svelte:component>
        {/if}
      {/key}
    </main>
    <HelpFab />
  {/if}
</div>
