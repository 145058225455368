<script lang="ts">
  import Loader from "./Loader.svelte";
  import ContextSetter from "../Components/ContextSetter.svelte";
  import { stringSepToNorm } from "@src/random";
  import { rx } from "../rxfsm";
  import { routeContext } from "../stores";

  import auth from "../auth";

  const userRoles = auth.claims.pipe(
    rx.filter((val) => val != null),
    rx.pluck("https://hasura.io/jwt/claims"),
    rx.pluck("x-hasura-allowed-roles"),
  );

  export let currentRoutePath;
  export let config;

  $: currentRoutePathSplit = currentRoutePath.split("/");
  $: currentRoutePathEnd = currentRoutePathSplit[currentRoutePathSplit.length - 1];

  const findRouteTabs = (routePath: string, source = config.routes) => {
    const splitRoutePath = routePath == "" || routePath == "/" ? ["index"] : routePath.split("/");

    const foundRoute = source.find((en) => {
      if (source.length == 1 && en.name.match(/\[(.*?)\]/g)) return true;

      return en.name == splitRoutePath[0];
    });

    if (foundRoute?.subroutes) return findRouteTabs(splitRoutePath.slice(1).join("/"), foundRoute.subroutes);
    if (foundRoute?.tabroutes)
      return {
        contextsetters: foundRoute.tabcontextsetters ?? [],
        tabs: foundRoute.tabroutes.map((tr) => ({
          name: tr.navName,
          route: tr.name,
          roles: tr.roles,
        })),
      };

    throw "No route found for " + routePath;
  };

  const { tabs, contextsetters }: { tabs: Array<any>; contextsetters: Array<any> } = findRouteTabs(currentRoutePath);

  let contextSettersDone = new Set();

  let prevWatchedRouteContext;
  const watchedRouteContext = routeContext.pipe(
    rx.filter((val) => {
      const valWithoutCur = { ...val, current: undefined };

      if (prevWatchedRouteContext === JSON.stringify(valWithoutCur)) return true;
      return false;
    }),
    rx.tap((val) => prevWatchedRouteContext = JSON.stringify(val)),
  );
</script>

<div class="mx-auto">
  <div class="pb-8 sm:px-0">
    <div class="flex md:flex-row-reverse flex-wrap">
      <div class="w-full md:w-1/4 md:pl-6 lg:pl-8 md:mb-0 mb-6">
        {#each contextsetters as contextsetter}
          <ContextSetter
            options={{ scale: 'sm', ...contextsetter }}
            useContext={'routeContext'}
            on:fieldhasvalue={({ detail }) => (contextSettersDone = new Set([...contextSettersDone, detail]))}
          />
        {/each}

        <div class="bg-white dark:bg-gray-800  border border-gray-400 dark:border-gray-900 overflow-hidden sm:rounded-lg">
          <div class="p-3">
            <nav class="flex flex-col space-y-1">
              {#each tabs as { name, route, roles }}
                {#if roles ? auth.helper.checkRoles($userRoles, roles) : true}
                  <a
                    href="#{[...currentRoutePathSplit.slice(0, currentRoutePathSplit.length - 1), route].join('/')}"
                    class:selected={route == currentRoutePathEnd}
                    class:notselected={route != currentRoutePathEnd}
                    class="focus:outline-none group font-medium"
                    aria-current="page"
                  >
                    <span class="truncate">{name ?? stringSepToNorm(route)}</span>
                  </a>
                {/if}
              {/each}
            </nav>
          </div>
        </div>
      </div>
      <div class="w-full md:w-3/4 md:pr-8 flex flex-col">
        {#if contextSettersDone.size == contextsetters.length}
          {#key $watchedRouteContext}
            <slot />
          {/key}
        {:else}
          <div
            class="bg-white dark:bg-gray-800  mb-5 align-middle inline-block min-w-full overflow-visible rounded-lg border border-gray-400 dark:border-gray-900"
          >
            <div class="bg-white dark:bg-gray-800  px-4 py-3 rounded-b-lg sm:px-6">
              <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                <div class="ml-4 mt-2 w-full">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  a {
    @apply w-full flex items-center px-3 py-2 text-sm leading-5 font-medium rounded-md transition ease-in-out duration-150;
  }

  :global(a.selected) {
    @apply text-secondary-900 bg-secondary-100;
  }

  :global(.dark a.selected) {
    @apply text-gray-200 bg-secondary-700;
  }

  :global(a.notselected) {
    @apply text-secondary-600;
  }

  :global(.dark a.notselected) {
    @apply text-gray-200;
  }

  :global(a.notselected:hover) {
    @apply text-secondary-900 bg-secondary-50;
  }

  :global(.dark a.notselected:hover) {
    @apply text-gray-200 bg-gray-700;
  }
</style>
