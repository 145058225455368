<script lang="ts">
  import { MONTH_NAMES, DAYS, pad } from "./helpers";
  import { clickOutside } from "@src/random";
  import { fade } from "svelte/transition";

  export let passClass = "";
  export let inputPassClass = "";
  export let id: string, value: string, required: boolean;
  export let showDatepicker = false;
  export let closeOnSelect = true;
  export let roundRightonFocus = true;

  let dateString: string;

  let viewingYear: number;
  let viewingMonth: number;

  let year: number;
  let month: number;
  let day: number;

  let no_of_days: Array<number> = [];
  let blankdays: Array<number> = [];

  $: if (value) {
    const splitValue = value.split("-");
    year = parseInt(splitValue[0]);
    month = parseInt(splitValue[1]);
    day = parseInt(splitValue[2]);
  }
  $: if (year && month && day) setOthers();

  const setOthers = () => {
    dateString = `${year}-${pad(month, 2)}-${pad(day, 2)}`
    value = dateString;

    if (no_of_days.length == 0) setNoOfDays();
  }

  const toggleDatePicker = () => {
    if (!value) {
      const now = new Date();
      year = now.getFullYear();
      month = now.getMonth() + 1;
      day = now.getDate();
    }

    setNoOfDays();

    showDatepicker = !showDatepicker;
  };

  const updateDatePart = ({ newYear, newMonth, newDay }) => {
    if (newYear) year = newYear;
    if (newMonth) month = newMonth;
    if (newDay) day = newDay;

    if (closeOnSelect) showDatepicker = false;
  };

  const setNoOfDays = () => {
    if (viewingYear == undefined) viewingYear = year;
    if (viewingMonth == undefined) viewingMonth = month - 1;

    const dayOfWeek = new Date(viewingYear, viewingMonth).getDay();
    let blankdaysArray = [];
    for (var i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    const daysInMonth = new Date(viewingYear, viewingMonth + 1, 0).getDate();
    let daysArray = [];
    for (var i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    blankdays = blankdaysArray;
    no_of_days = daysArray;
  };
</script>

<style>
  :global(.datepicker) {
    border: 1px solid #aaa;
  }
</style>

<div class="relative {passClass}">
  <div class="relative {roundRightonFocus || !showDatepicker ? 'rounded-md' : 'rounded-l-md'} shadow-sm">
    <input
      value={dateString ?? ""}
      type="text"
      on:keydown|preventDefault
      on:paste|preventDefault
      placeholder="Date"
      {id}
      {required}
      on:click={toggleDatePicker}
      class="form-input {inputPassClass} {roundRightonFocus || !showDatepicker ? '' : 'rounded-r-none'} block w-full text-left
        sm:text-sm sm:leading-5 transition ease-in-out duration-150" />
  </div>
  {#if showDatepicker}
    <div
      use:clickOutside={{ callback: toggleDatePicker, exclusions: [id] }}
      class="datepicker bg-white dark:bg-gray-800  mt-12 rounded-lg shadow py-2 px-3 absolute top-0 right-0 z-50 flex flex-row space-x-2"
      style="width: 23rem"
      transition:fade={{ duration: 150 }}>
      <div class="flex flex-col">
        <div class="flex flex-row flex-no-wrap justify-between items-center mb-2">
          <div class="flex flex-row flex-no-wrap">
            <span class="text-lg font-bold text-secondary-800 dark:text-gray-200">{MONTH_NAMES[viewingMonth]}</span>
            <span class="ml-3 text-lg text-secondary-600 font-normal">
              <input
                class="p-0 pl-1 w-full dark:bg-gray-800"
                type="text"
                bind:value={viewingYear}
                on:change={(val) => {
                  setNoOfDays();
                }} />
            </span>
          </div>
          <div class="flex flex-row flex-no-wrap">
            <button
              type="button"
              class="{viewingMonth == 0 ? 'cursor-not-allowed opacity-25' : ''} transition ease-in-out duration-100 inline-flex
                cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
              disabled={viewingMonth == 0}
              on:click={() => {
                viewingMonth--;
                setNoOfDays();
              }}>
              <svg class="h-6 w-6 text-secondary-500 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button
              type="button"
              class="{viewingMonth == 11 ? 'cursor-not-allowed opacity-25' : ''} transition ease-in-out duration-100
                inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
              disabled={viewingMonth == 11}
              on:click={() => {
                viewingMonth++;
                setNoOfDays();
              }}>
              <svg class="h-6 w-6 text-secondary-500 inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>

        <div class="flex flex-wrap mb-3 -mx-1">
          {#each DAYS as _day}
            <div style="width: 14.26%" class="px-0.5">
              <div class="text-secondary-800 dark:text-gray-200 font-medium text-center text-xs">{_day}</div>
            </div>
          {/each}
        </div>

        <div class="flex flex-wrap -mx-1">
          {#each blankdays as blankday}
            <div style="width: 14.28%" class="text-center border p-1 border-transparent text-xs" />
          {/each}
          {#each no_of_days as date}
            <div style="width: 14.28%" class="px-0.5 mb-0.5">
              <div
                on:click={() => updateDatePart({ newYear: viewingYear, newMonth: viewingMonth + 1, newDay: date })}
                class="{year == viewingYear && month == viewingMonth + 1 && day == date ? 'bg-secondary-500 text-white' : 'text-secondary-700 hover:bg-secondary-200 dark:text-secondary-200 dark:hover:bg-secondary-500'}
                    cursor-pointer text-center text-xs rounded-full leading-loose transition ease-in-out
                    duration-100">
                {date}
              </div>
            </div>
          {/each}
        </div>
      </div>
      <div class="flex flex-col justify-between">
        <div class="flex flex-row space-x-4">
        </div>
        <button
          type="button"
          class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full focus:outline-none"
          on:click={() => {
            toggleDatePicker();
            value = undefined;
            dateString = undefined;
          }}>
          <span class="text-secondary-500 inline-flex mx-auto my-1 text-center"> Clear </span>
        </button>
      </div>
    </div>
  {/if}
</div>
