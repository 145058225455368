<script>
  import { systemContext, systemExtendedContext } from "../stores";
  import { clickOutside } from "../random";
  let help = false;

  let colorSchemeSetting = currentTheme();

  function changeColorSchemeSetting() {
    switch (colorSchemeSetting) {
      case "Light":
        localStorage.removeItem("theme");
        break;
      case "Dark":
        localStorage.theme = "light";
        break;
      default:
        localStorage.theme = "dark";
        break;
    }

    colorSchemeSetting = currentTheme();
    updateTheme();
  }

  function currentTheme() {
    if (localStorage.theme == "light") return "Light";
    if (localStorage.theme == "dark") return "Dark";

    return "Default";
  }

  function updateTheme() {
    if (localStorage.theme === "dark" || (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }
</script>

<div class="fixed bottom-6 right-6 z-50" use:clickOutside={{ callback: () => (help = false), exclusions: [] }}>
  <div class="origin-bottom-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bottom-16">
    {#if help}
      <div class="rounded-md bg-white dark:bg-gray-800  shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <div class="py-1">
          <a
            href={$systemExtendedContext.support}
            target="_blank"
            class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 dark:text-gray-300 hover:bg-white  hover:text-gray-900 dark:hover:text-gray-200 focus:outline-none focus:bg-white dark:bg-gray-800  focus:text-gray-900"
            role="menuitem"
          >
            <svg
              class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-200 group-focus:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
              />
            </svg>
            Help and Support
          </a>
          <div class="border-t border-gray-200 dark:border-gray-400" />
          <div class="py-1">
            <div class="text-xs group flex items-center px-4 pt-2 leading-5 text-gray-800 dark:text-gray-300 focus:outline-none" role="menuitem">
              {$systemContext.system.label}: Version
              {$systemExtendedContext.version}
            </div>
            <span class="text-xs group flex items-center px-4 pt-1 leading-5 text-gray-800 dark:text-gray-300 focus:outline-none" role="menuitem">
              Color Scheme:
              <button
                on:click={changeColorSchemeSetting}
                class="focus:outline-none ml-1 text-primary-500 dark:text-primary-300 underline"
              >{colorSchemeSetting}</button>
            </span>
            <div class="text-green-500 text-xs group flex items-center px-4 pt-1 leading-5 focus:text-gray-900" role="menuitem">All Systems Operational</div>
          </div>
        </div>
      </div>
    {/if}
  </div>
  <span
    class="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-800 hover:bg-opacity-75 cursor-pointer"
    on:click={() => {
      help = !help;
    }}
  >
    <span class="text-xl font-medium leading-none text-white">?</span>
  </span>
</div>
