<script lang="ts">
  import GeneralHeading from "../General/GeneralHeading.svelte";
  import FormField from "./Form/FormField.svelte";
  import Loader from "../General/Loader.svelte";
  import { QueryStore } from "../GraphQL/query";
  import { getQueryFirstSelectionName } from "../GraphQL/helpers";
  import { mutateClient } from "../GraphQL/mutate";
  import { routeContext } from "../stores";

  import gql from "graphql-tag";

  export let options;

  const queryStore = new QueryStore({ query: options?.query });
  const queryDataStore = queryStore.getDataStore();

  let fetchedInitial = false;
  let fieldValues = {};
  let fieldErrors = {};

  $: if (!fetchedInitial && $queryDataStore?.data?.data) {
    fieldValues = Object.fromEntries(Object.entries($queryDataStore["data"]["data"] as Array<any>).filter(([k, v]) => k.substring(0, 2) != "__"));
    fetchedInitial = true;
  }

  $: if (fetchedInitial && !$routeContext.current) {
    $routeContext.current = { form: { field: fieldValues } };
  };

  const saveField = async (fieldData) => {
    const updateMutation = `mutation Mutation {
      update_${getQueryFirstSelectionName(options.query)} {
        __typename
      }
    }`;

    const updateMutationDoc = { ...gql(updateMutation) };

    const formQueryArguments = { ...gql(options.query)["definitions"][0]["selectionSet"]["selections"][0]["arguments"] };

    let fieldType;

    if (fieldData.fieldVarType) {
      fieldType = fieldData.fieldVarType;
    } else {
      switch (fieldData.type) {
        case "bigtext":
        case "text":
        case "select":
        case undefined:
          fieldType = "String";
          break;
        case "number":
          fieldType = "Int";
          break;
        case "decimal":
          fieldType = "numeric";
          break;
        case "subform":
        case "jsontasks":
          fieldType = "jsonb";
          break;
        case "datetime":
          fieldType = "timestamptz";
          break;
        case "date":
          fieldType = "date";
          break;
        case "toggle":
          fieldType = "Boolean";
          break;
        default:
          fieldType = "MISSING";
          break;
      }
    }

    updateMutationDoc["definitions"][0]["variableDefinitions"] = [
      {
        kind: "VariableDefinition",
        variable: formQueryArguments[0]["value"],
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "uuid",
            },
          },
        },
        directives: [],
      },
      {
        kind: "VariableDefinition",
        variable: {
          kind: "Variable",
          name: {
            kind: "Name",
            value: fieldData["field"],
          },
        },
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: fieldType,
            },
          },
        },
        directives: [],
      },
    ];

    updateMutationDoc["definitions"][0]["selectionSet"]["selections"][0]["arguments"] = [
      {
        kind: "Argument",
        name: {
          kind: "Name",
          value: "pk_columns",
        },
        value: {
          kind: "ObjectValue",
          fields: [
            {
              kind: "ObjectField",
              name: formQueryArguments[0]["name"],
              value: formQueryArguments[0]["value"],
            },
          ],
        },
      },
      {
        kind: "Argument",
        name: {
          kind: "Name",
          value: "_set",
        },
        value: {
          kind: "ObjectValue",
          fields: [
            {
              kind: "ObjectField",
              name: {
                kind: "Name",
                value: fieldData["field"],
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: fieldData["field"],
                },
              },
            },
          ],
        },
      },
    ];

    let variables = {};

    variables[fieldData["field"]] = fieldValues[fieldData["field"]];

    let saveSuccessful = true;

    try {
      await mutateClient({
        mutation: updateMutationDoc,
        variables,
      });

      $routeContext.current = { form: { field: fieldValues } };
    } catch (e) {
      saveSuccessful = false;

      console.log({ e });
      switch (e.message) {
        case "GraphQL error: null value found for non-nullable type: Int!":
          fieldErrors[fieldData["field"]] = "Invalid field value.";
          break;
        default:
          throw e;
      }
    }

    return saveSuccessful;
  };
</script>

<div class="bg-white dark:bg-gray-800  mb-5 align-middle inline-block min-w-full overflow-visible rounded-lg border border-gray-400 dark:border-gray-900">
  <GeneralHeading title={options.label} />
  <div class="bg-white dark:bg-gray-800  px-4 py-3 rounded-b-lg sm:px-6">
    <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
      <div class="ml-4 mt-2 w-full">
        {#if fetchedInitial}
          <div class="flex flex-wrap w-full">
            {#each options.fields as fieldData}
              <FormField
                bind:value={fieldValues[fieldData['field']]}
                bind:error={fieldErrors[fieldData['field']]}
                onSave={async () => await saveField(fieldData)}
                passClass="p-2"
                {fieldData}
              />
            {/each}
          </div>
        {:else}
          <Loader />
        {/if}
      </div>
    </div>
  </div>
</div>
