export default {
  "prettifyBolean": {
    "args": [
      "color1",
      "color2"
    ],
    "return": {
      "true": {
        "tag": {
          "color": "$args_color1"
        }
      },
      "false": {
        "tag": {
          "color": "$args_color2"
        }
      }
    }
  }
}

export function parseJSONFunction(name, json) {
  try {
    const jsonObj = json;
    const argNameByIndex = { ...jsonObj.args };

    return (...args) => {
      let returnObj = JSON.stringify(json.return);

      for (const [i, arg] of Object.entries(args)) {
        returnObj = returnObj.replace(`$args_${argNameByIndex[i]}`, arg);
      }

      return JSON.parse(returnObj);
    };
  } catch(e) {
    console.error(e);
    return {};
  }
}