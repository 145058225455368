<script lang="ts">
  import { clickOutside, setUpper } from "@src/random";
  import { fade } from "svelte/transition";

  import Icon from "../Icon.svelte";

  export let navigateCallback,
    route,
    currentRoutePath,
    extraClass,
    inline = false,
    icon = null;
  const currentPageCls = "text-white bg-secondary-500";
  const otherPageLinkCls = "text-secondary-300 hover:text-white hover:bg-secondary-600";
  const anyPageLinkCls =
    "px-3 py-2 rounded-md font-medium focus:border-none focus:outline-none focus:text-white focus:bg-secondary-600 text-xs flex " + extraClass;
  const isCurrentPage = (currentRoutePath && currentRoutePath === route.route) || (currentRoutePath === undefined && route.route === "");

  let showDialog = false;

  const setDialog = (val) => () => {
    showDialog = val === "toggle" ? !showDialog : val;
  };

  const isARouteCurrentPage = (route: string): boolean => {
    return (currentRoutePath && currentRoutePath === route) || (currentRoutePath === "" && route === "index");
  };
</script>

<div class="relative text-left font-medium" on:mouseleave={setDialog(false)}>
  {#if icon}
    <button
      class="p-2 text-secondary-400 rounded-full
      hover:text-white focus:outline-none focus:text-white"
      aria-haspopup="true"
      id={'navDrop-' + route.name}
      on:click={setDialog('toggle')}
      aria-label={route.name ? route.name : route.route
            .split('-')
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join('')}
    >
      <Icon {icon} />
    </button>
  {:else}
    <button
      class="{isCurrentPage ? currentPageCls : otherPageLinkCls}
      {anyPageLinkCls}"
      aria-label="Button"
      aria-haspopup="true"
      id={'navDrop-' + route.name}
      on:click={setDialog('toggle')}
    >
      {route.name}
    </button>
  {/if}

  {#if showDialog}
    {#if inline}
      <div class="flex flex-col my-1 relative text-left font-medium">
        {#each route.subroutes as subroute}
          <a
            href="#{subroute.route}"
            on:click={() => {
              if (navigateCallback) navigateCallback();
              setDialog(false)();
            }}
            class="anySubPageLink text-xs my-auto font-medium"
            class:currentPage={isARouteCurrentPage(subroute.route)}
            class:otherPageLink={!isARouteCurrentPage(subroute.route)}
          >
            {subroute.name ? subroute.name : subroute.route
                  .split('/')
                  .map((s) => setUpper(s))
                  [subroute.route.split('/').length - 1].split('-')
                  .map((s) => setUpper(s))
                  .join(' ')}
          </a>
        {/each}
      </div>
    {:else}
      <div class="origin-top-right absolute {icon ? 'right-0' : 'left-0'} w-56 rounded-md shadow-lg" style="z-index: 1000;" transition:fade={{ duration: 150 }}>
        <div class="bg-secondary-700 h-2" />
        <div class="rounded-md bg-white dark:bg-gray-800  shadow-xs">
          {#each route.subroutes as subroute}
            <a
              href="#{subroute.route}"
              on:click={() => {
                if (navigateCallback) navigateCallback();
                setDialog(false)();
              }}
              class="block px-4 py-2 text-xs leading-5 text-secondary-700
              hover:bg-secondary-100 hover:text-secondary-900 focus:outline-none dark:text-gray-200
              dark:hover:bg-secondary-700 dark:hover:text-gray-200"
              role="menuitem"
            >
              {subroute.name ? subroute.name : subroute.route
                    .split('/')
                    .map((s) => setUpper(s))
                    [subroute.route.split('/').length - 1].split('-')
                    .map((s) => setUpper(s))
                    .join(' ')}
            </a>
          {/each}
        </div>
      </div>
    {/if}
  {/if}
</div>

<style>
  .currentPage {
    @apply text-white bg-secondary-500;
  }
  .otherPageLink {
    @apply text-secondary-300;
  }
  .otherPageLink:hover {
    @apply text-white bg-secondary-600;
  }
  .anySubPageLink {
    @apply px-6 py-2 rounded-md font-medium;
  }
  .anySubPageLink:focus {
    @apply outline-none;
  }
</style>
