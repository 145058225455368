<script context="module">
  export function getPrettifyOptions(optionsForKey, functions) {
    if (Array.isArray(optionsForKey)) {
      const functionFinder = /\$functions_(.*)/g;

      const functionName = functionFinder.exec(optionsForKey[0])[1];
      const functionArgs = optionsForKey.slice(1);

      const func = functions[functionName];

      if (!func) throw new Error("No function defined with name " + functionName);

      return func(...functionArgs);
    } else {
      return optionsForKey ?? {};
    }
  }
</script>

<script>
  export let data;
  export let prettifyOptionsForData = {};

  $: useData = data ?? "";

  $: prettifyWith = (Object.entries(prettifyOptionsForData)?.find(([matchPattern, options]) => new RegExp(matchPattern).test(useData)) ?? ["", {}])[1];
  $: prettifyClasses = Object.entries(prettifyWith)
    .map(([type, opts]) => {
      switch (type) {
        case "font":
          return [...opts]
            .map((en) => {
              switch (en) {
                case "^":
                  return "uppercase";
                case "b":
                  return "font-bold";
                case "i":
                  return "italic";
                case "u":
                  return "underline";
                case "l":
                  return "line-through";
              }
            })
            .join(" ");
        case "fontSize":
          return `text-${opts}`;
        case "format":
          return ""; //This is also special, used below
        case "text":
          return ""; //This is special, used below
        case "textColor":
          return `text-${opts}`;
        case "dark:textColor":
          return `dark:text-${opts}`;
        case "tag":
          return `inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium bg-${opts.color}-100 text-${opts.color}-800`;
      }
    })
    .join(" ");
  $: forceText = prettifyWith.text?.replace('$actual', format(useData));
  
  const format = (unformatted) => {
    if (unformatted === "") return "";

    switch (prettifyWith["format"]) {
      case "datetime":
        const formatter = new Intl.DateTimeFormat("en", {
          timeStyle: "short",
          dateStyle: "medium",
        });

        return formatter.format(new Date(unformatted)).replace(",", ""); //Replaces the first comma, to end up same as mobile
      default:
        return unformatted;
    }
  };
</script>

<span class={prettifyClasses}> {forceText ?? format(useData)} </span>
