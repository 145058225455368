<script>
  import { useMachine, transition, invoke, rx, passObsToProm, throwOnNull, reduce, matchState, firstValueFrom, action } from "./rxfsm";
  import { routeHashObs, routeMatch, routeStore, systemExtendedContext } from "./stores";
  import { trySet } from "./random";
  import { onMount } from "svelte";

  import { Provider } from "./provider";

  import loggedinMachine from "./Views/loggedin.machine";
  import loggedoutMachine from "./Views/loggedout.machine";

  import Tailwindcss from "./General/Tailwindcss.svelte";
  import Notifications from "./General/Notifications.svelte";
  import SlideoverPanel from "./General/SlideoverPanel.svelte";
  import Modal from "./General/Modal.svelte";

  import Loggedin from "./Views/Loggedin.svelte";
  import Loggedout from "./Views/Loggedout.svelte";
  import Loading from "./Views/Loading.svelte";

  import auth from "./auth";
  import interceptFetch from "./interceptfetch";

  interceptFetch({
    augmentRequest: async function interceptFetchRequest([url, config]) {
      const interceptedURL = new URL(url);

      const hasuraURL = new URL("https://" + globalThis.__ENV__.__HASURA_URL__);
      const serverURL = trySet(async () => new URL(await firstValueFrom(systemExtendedContext.pipe(rx.pluck("server")))), {});

      if (interceptedURL.hostname === hasuraURL.hostname || interceptedURL.origin === serverURL?.origin) {
        const { token: maybeToken, expirationTime } = await auth.helper.getToken();

        const tokenExp = new Date(expirationTime);
        const minFromNow = new Date(new Date() + 60000);

        const goodToken = tokenExp > minFromNow ? maybeToken : (await auth.helper.getToken({ forceRefresh: true })).token;

        const newConfig = {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${goodToken}`,
          },
        };

        return [url, newConfig];
      }

      return [url, config];
    },
  });

  import { initClient } from "./GraphQL/client";

  initClient();

  import { handleError } from "./sentry";

  const [authStateMain, authContext, authSender] = useMachine({
    checking: invoke(
      () => passObsToProm(auth.authState, throwOnNull),
      transition("done", "loggedin"),
      transition(
        "error",
        "loggedout",
        action(() => systemExtendedContext.set({})),
        reduce((ctx, ev) => {
          if (ev.error !== "Value was null") console.error(ev.error);
          return { ...ctx };
        }),
      ),
    ),
    loggedin: loggedinMachine(() => authContext),
    signingout: invoke(
      auth.logout,
      transition(
        "done",
        "loggedout",
        action(() => (($routeStore.fragment = "#"), systemExtendedContext.set({}))),
        action(() => {
          localStorage.removeItem("systemExtendedContext");
          localStorage.removeItem("systemExtendedContextSysId");
          localStorage.removeItem("systemContext");
        }),
      ),
      transition(
        "error",
        "loggedout",
        action(() => (($routeStore.fragment = "#"), systemExtendedContext.set({}))),
        action(() => {
          localStorage.removeItem("systemExtendedContext");
          localStorage.removeItem("systemExtendedContextSysId");
          localStorage.removeItem("systemContext");
        }),
        reduce((ctx, ev) => {
          console.error(ev.error);
          return { ...ctx };
        }),
      ),
    ),
    loggedout: loggedoutMachine(() => authContext),
  });

  const authState = authStateMain.pipe(rx.pluck("current"));

  $: if (routeMatch("/profile/logout", $routeHashObs, true)) {
    authSender.child["signout"]();
  }

  let modalDiv;

  onMount(async () => {
    window.onunhandledrejection = (e) => handleError(e);
    window.onerror = (message) => handleError({ reason: message });
  });
</script>

<Tailwindcss />

<Modal bind:modalDiv />
<Notifications />
<SlideoverPanel bind:modalDiv />

<div use:Provider={['authState', [authStateMain, authContext, authSender]]}>
  {#if matchState($authState, 'checking', 'signingout')}
    <Loading />
  {:else if $authState === 'loggedout'}
    <Loggedout />
  {:else if $authState === 'loggedin'}
    <Loggedin />
  {/if}
</div>
