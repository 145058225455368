import firebase from "firebase/app";
import "firebase/auth";

import { writable } from "svelte-pipeable-store";
import { debounce } from "./rxfsm";

const firebaseConfig = globalThis.__ENV__.__FIREBASE__;
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

/* Start: Auth Handlers */

const handleResetPassword = async ({ password }) => {
  const queryString = "?" + window.location.hash.substr(1).split("?")[1];
  const urlParams = new URLSearchParams(queryString);
  const actionCode = urlParams.get("oobCode");

  const accountEmail = await auth.verifyPasswordResetCode(actionCode);

  await auth.confirmPasswordReset(actionCode, password);

  return { rememberme: true, email: accountEmail, password };
};

const sendPasswordResetEmail = ({ email }) => auth.sendPasswordResetEmail(email);

const loginWithEmailPassword = async ({ rememberme, email, password }) => {
  const persistance = rememberme ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION;

  await auth.setPersistence(persistance);

  await auth.signInWithEmailAndPassword(email, password);
};

const logout = async () => {
  await debounce(400);
  await auth.signOut();
};

/* End: Auth Handlers */

/* Start: Auth State Observables */

const userState = writable();
auth.onAuthStateChanged(userState.set);

const authState = writable();
const claims = writable();

auth.onIdTokenChanged((user) => {
  if (user) user.getIdTokenResult().then(res => {
    claims.set(res.claims);
  });

  authState.set(user);
})

/* End: Auth State Observables */

/* Start: Auth Helpers */

const getToken = async ({ forceRefresh = false } = {}) => {
  if (!auth.currentUser) throw new Error("Not signed in"); //TODO: Consider logging out here? Or avoiding reaching this state by having a shared state between tabs?
  return await auth.currentUser.getIdTokenResult(forceRefresh);
}

const checkRoles = (userRoles, roles) => {
  return roles.some((r) => userRoles.indexOf(r) >= 0);
};

/* End: Auth Helpers */

export default {
  handleResetPassword,
  sendPasswordResetEmail,
  loginWithEmailPassword,
  logout,
  userState,
  authState,
  claims,
  helper: {
    checkRoles,
    getToken,
  }
};
