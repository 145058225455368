<script lang="ts">
  import type { Writable } from "svelte/store";
  import { routeContext, systemContext } from "../stores";

  import GeneralHeading from "../General/GeneralHeading.svelte";
  import { QueryStore } from "../GraphQL/query";
  import { stringSepToNorm } from "../random";
  import { Provider } from "../provider";

  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let options;
  export let useContext = "systemContext";

  const contextStore: Writable<any> = useContext == "systemContext" ? systemContext : useContext == "routeContext" ? routeContext : null;
  let fieldInContext = {};

  let queriedOptions = options?.options ?? [];
  let open = false;

  $: fieldInContext = $contextStore[options?.field] ?? {};

  $: scale = options.scale ?? "md";

  const selectOption = (event, option) => {
    event.stopPropagation();

    if (useContext == "systemContext") {
      const [, , authSender] = Provider.of("authState", self);
      const sendContexts = (systemContext) => authSender.child["changecontexts"]({ changingContext: options?.field, systemContext });

      switch (options?.field) {
        case "organization":
          sendContexts({ organization: option });
          break;
        case "system":
          sendContexts({ organization: $contextStore.organization, system: option });
          break;
        default:
          sendContexts({ ...$contextStore, [options?.field]: option });
          break;
      }
    } else {
      $contextStore[options?.field] = option;
    }

    open = false;
  };

  let self;

  $: if (queryDataStore) queriedOptions = $queryDataStore?.data?.data ?? [];
  let queryDataStore;

  if (options.optionsQuery) {
    const queryStore = new QueryStore({ query: options?.optionsQuery });
    queryDataStore = queryStore.getDataStore();
  }

  $: if (Object.keys(fieldInContext).length > 0) {
    dispatch("fieldhasvalue", options?.field);
  }
</script>

<div
  bind:this={self}
  class="bg-white dark:bg-gray-800 mb-5 align-middle inline-block min-w-full overflow-visible rounded-lg border border-gray-400 dark:border-gray-900"
>
  <GeneralHeading title={stringSepToNorm(options?.field)} {scale} />
  <div class="bg-white dark:bg-gray-800 px-3 py-3 border-b border-gray-200 dark:border-gray-700 rounded-b-lg">
    <div class="flex items-center justify-between flex-wrap sm:flex-no-wrap">
      <div class="w-full">
        <div class="flex flex-row space-x-2 w-full">
          <div class="mt-1 w-full flex rounded-md shadow-sm">
            <div class="relative h-full w-full flex-grow focus-within:z-10">
              <button
                on:click={() => (open = !open)}
                class="form-input dark:bg-gray-800 p-0 block w-full h-full sm:text-sm sm:leading-5 transition ease-in-out duration-150"
              >
                <div class="flex flex-col w-full">
                  <div class="{scale == 'sm' ? 'small-box' : 'box'}  justify-between">
                    <div class="flex flex-row w-full">
                      {#if fieldInContext?.image}
                        <div class="flex"><img alt="System Logo" src={fieldInContext.image} /></div>
                      {/if}
                      <div class="flex flex-col truncate whitespace-no-wrap">
                        <span class="my-auto text-{scale} leading-6 font-medium">{fieldInContext?.label ?? 'Not Set'}</span>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        class="mt-auto transform rotate-180"
                        style="margin-bottom: 2px"
                        fill="none"
                      >
                        <path d="M5 7.5L0.669872 0L9.33013 0L5 7.5Z" fill="currentColor" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" class="mb-auto" style="margin-top: 2px" fill="none">
                        <path d="M5 7.5L0.669872 0L9.33013 0L5 7.5Z" fill="currentColor" />
                      </svg>
                    </div>
                  </div>
                  {#if open}
                    <div class="contexts border-t border-gray-200 dark:border-gray-400 max-h-40 customscrollbar">
                      {#each queriedOptions ?? [] as option}
                        {#if fieldInContext?.label != option.label}
                          <button
                            on:click={(ev) => selectOption(ev, option)}
                            class="{scale == 'sm' ? 'small-box' : 'box'}  hover:bg-secondary-50 dark:hover:bg-secondary-700  focus:outline-none"
                          >
                            <div class="flex flex-row w-full">
                              {#if option.image}
                                <div class="flex"><img alt="System Logo" src={option.image} /></div>
                              {/if}
                              <div class="flex flex-col truncate whitespace-no-wrap">
                                <span class="my-auto text-{scale} leading-6 font-medium">{option.label}</span>
                              </div>
                            </div>
                          </button>
                        {/if}
                      {:else}
                        <div class="{scale == 'sm' ? 'small-box' : 'box'} ">
                          <div class="flex flex-row w-full">
                            <div class="flex flex-col truncate whitespace-no-wrap">
                              <span class="my-auto text-{scale} leading-6 font-medium">No options found</span>
                            </div>
                          </div>
                        </div>
                      {/each}
                    </div>
                  {/if}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .box {
    @apply h-10 flex flex-row w-full px-10 py-2;
  }
  .box > div > div + div,
  .small-box > div > div + div {
    margin-left: 15px;
  }

  .small-box {
    @apply h-10 flex flex-row w-full px-3 py-2;
  }

  :global(.dark .contexts.customscrollbar) {
    --scrollbarBG: #1c1c20;
  }

  img {
    width: 33px;
    height: 33px;
    margin-top: auto;
    margin-bottom: auto;
  }
</style>
