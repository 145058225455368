<script type="ts">
  import { notificationOpts } from "../stores";
  import { onMount } from "svelte";

  import Yace from "./JsonEditor/Yace.js";
  import history from "yace/dist/plugins/history.js";
  import tab from "yace/dist/plugins/tab.js";

  import { QueryStore } from "../GraphQL/query";
  import { mutateClient } from "../GraphQL/mutate";
  import { Provider } from "../provider";

  export let options;

  let editor, editorDiv;

  const plugins = [
    history(), // suuport ctrl+z ctrl+shift+z when use plugins. should be first
    tab(), // indent with two space
  ];

  const format = (useValue = editor?.value) => {
    let hadError = false;

    try {
      const parsedJson = JSON.parse(useValue);
      editor.update({ value: JSON.stringify(parsedJson, undefined, 2) });
    } catch (e) {
      hadError = true;

      if (e.name == "SyntaxError") {
        const errorSpot = parseInt(e.message.replace(/Unexpected (.*) in JSON at position /g, ""));

        editor.textarea.focus();
        editor.update({ selectionStart: errorSpot, selectionEnd: errorSpot + 1 });

        notificationOpts.set({
          title: "JSON Error Highlighted",
          color: "red",
          icon: "info",
          timeout: 5000,
        });
      } else {
        throw e;
      }
    }

    return { hadError };
  };
  const save = async () => {
    const { hadError } = format();
    if (!hadError) {
      await mutateClient({
        mutation: options.mutation,
        variables: {
          editor_value: JSON.parse(editor.value),
        },
      });

      if (options.changecontexts) {
        //@ts-ignore
        const [, , authSender] = Provider.of("authState", editorDiv);
        authSender.child["changecontexts"]({ changingContext: options.changecontexts, stopRedirectAfterChangeContext: true });
      } else {
        notificationOpts.set({
          title: "Saved Value",
          color: "green",
          icon: "info",
          timeout: 5000,
        });
      }
    }
  };

  const queryStore = new QueryStore({ query: options?.query });
  const queryDataStore = queryStore.getDataStore();

  $: if (editor && $queryDataStore?.data?.data) editor.update({ value: JSON.stringify($queryDataStore["data"]["data"]["data"], undefined, 2) });

  function handleKeydown(event) {
    if (event.key === "s" && event.ctrlKey === true) {
      event.preventDefault();

      save();
    }
  }

  onMount(() => {
    editor = new Yace(editorDiv, {
      value: JSON.stringify($queryDataStore, undefined, 2),
      plugins,
    });

    editor.textarea.spellcheck = false;
    editor.root.className = "customscrollbar";
  });
</script>

<svelte:window on:keydown={handleKeydown} />

<div class="jsoneditor bg-white dark:bg-gray-800 rounded-lg shadow px-5 py-6 sm:px-6 mb-5">
  <div class="rounded-lg flex flex-row" style="min-height: 55vh">
    <span class="relative z-0 inline-flex shadow-sm rounded-md mb-auto">
      <button type="button" on:click={() => format()} class="rounded-l-md"> Format </button>

      <button type="button" on:click={save} class="-ml-px rounded-r-md"> Save </button>
    </span>
    <div class="editor-container">
      <div bind:this={editorDiv} />
    </div>
  </div>
</div>

<style global>
  .jsoneditor button {
    @apply relative inline-flex items-center px-4 py-2 border border-secondary-300 bg-white  text-sm leading-5 font-medium text-gray-700 transition ease-in-out duration-150;
  }

  .dark .jsoneditor button {
    @apply bg-gray-800 text-gray-400;
  }

  .jsoneditor button:hover {
    @apply text-gray-500;
  }

  .dark .jsoneditor button:hover {
    @apply text-gray-200;
  }

  .jsoneditor button:focus {
    @apply z-10 outline-none border-blue-300 shadow-outline-blue;
  }

  .jsoneditor button:active {
    @apply bg-gray-100 text-gray-700;
  }

  .dark .jsoneditor button:active {
    @apply bg-gray-800 text-gray-300;
  }

  .jsoneditor .editor-container {
    @apply h-full pl-6;
    width: 85%;
    position: relative;
    border: none;
    line-height: 1.5;
    color: #669900;
  }

  .jsoneditor .editor-container textarea {
    caret-color: black;
  }

  .dark .jsoneditor .editor-container {
    color: #a6e22e;
  }

  .dark .jsoneditor .editor-container .customscrollbar {
    --scrollbarBG: #1c1c20;
  }

  .dark .jsoneditor .editor-container textarea {
    caret-color: white;
  }

  .jsoneditor .editor-container pre {
    counter-reset: line;
  }
  .jsoneditor .editor-container pre code {
    padding-left: 2.8rem;
    white-space: pre;
    counter-increment: line;
  }

  .jsoneditor .editor-container pre code:before {
    width: 1.5rem;
    padding-right: 1.5rem;
    left: 0;
    white-space: nowrap;
    position: absolute;
    -webkit-user-select: none;
    content: counter(line);
  }
</style>
