<script>
  import { systemContext } from "../../stores";
  import { rx } from "../../rxfsm";

  import { fade } from "svelte/transition";

  import auth from "../../auth";

  import Icon from "../Icon.svelte";

  const orgName = systemContext.pipe(rx.pluck("organization"), rx.pluck("label"));
  const userName = auth.userState.pipe(rx.pluck("displayName"));
  const userRoles = auth.claims.pipe(
    rx.filter((val) => val != null),
    rx.pluck("https://hasura.io/jwt/claims"),
    rx.pluck("x-hasura-allowed-roles"),
  );

  export let userdropdown, routes;

  $: body = userdropdown?.body ?? [];

  async function importComponent(component) {
    const Component = await import(`../../Components/${component}.svelte`);

    return await Component.default;
  }

  let showDialog = false;

  const setDialog = (val) => () => {
    showDialog = val === "toggle" ? !showDialog : val;
  };
</script>

<div class="dropdown" on:mouseleave={setDialog(false)}>
  <button on:click={setDialog('toggle')} type="button" class="focus:outline-none inline-block w-full rounded-md shadow-sm group">
    <div
      class="border border-secondary-300 group-hover:border-white dark:bg-blackish duration-150 ease-in-out focus:border-primary-300 focus:outline-none
      focus:shadow-outline-primary pl-3 pr-10 py-1 relative rounded-md sm:leading-5 text-left text-secondary-300 group-hover:text-white transition w-full font-medium"
    >
      <div class="flex items-center space-x-3">
        <Icon icon="user" />
        <div>
          <span class="block truncate" style="font-size: 0.7rem; line-height: 1rem">{$userName}</span>
          <span class="block truncate" style="font-size: 0.55rem; line-height: 0.75rem">{$orgName}</span>
        </div>
      </div>
      <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <svg class="h-5 w-5 text-secondary-300 group-hover:text-white" viewBox="0 0 20 20" fill="none" stroke="currentColor"><path
            d="M7 7l3-3 3 3m0 6l-3 3-3-3"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
  </button>

  {#if showDialog}
    <div class="dialog" transition:fade={{ duration: 150 }}>
      <div class="bg-secondary-700 h-2" />
      <div class="rounded-md bg-white dark:bg-gray-800  shadow-xs py-4 flex divide-x-2 divide-gray-200 dark:divide-gray-400">
        <div class="content" style="min-width: 35%">
          <span class="mb-4 font-bold">Astral Platform</span>
          {#each routes as route}
            {#if route['roles'] ? auth.helper.checkRoles($userRoles, route['roles']) : true}
              <a href="#{route.route === 'index' ? '' : route.route}" on:click={setDialog(false)} class="text-xs font-medium hover:text-secondary-400">
                {route.name ? route.name : route.route
                      .split('-')
                      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
                      .join(' ')}
              </a>
            {/if}
          {/each}
          <span style="margin: auto" />
          <a href="#profile/logout" class="text-xs leading-5 font-medium hover:text-secondary-400"> Logout </a>
        </div>
        <div class="content" style="width: 100%">
          {#each body as component}
            {#if component['roles'] ? auth.helper.checkRoles($userRoles, component['roles']) : true}
              {#await importComponent(component['item']) then importedComponent}
                <svelte:component this={importedComponent} options={component['options']} />
              {/await}
            {/if}
          {/each}
        </div>
      </div>
    </div>
  {/if}
</div>

<style global>
  div.dropdown {
    @apply relative text-left font-medium;
  }

  @media (max-width: 640px) {
    /*@screen sm*/
    div.dropdown > div.dialog {
      width: 100% !important;
    }
  }

  div.dropdown > div.dialog {
    @apply origin-top-right absolute right-0 rounded-md shadow-lg;

    width: 30rem;
    z-index: 1000;
  }

  div.dropdown > div.dialog div.content {
    @apply px-4 pb-2 flex flex-col;
  }

  div.dropdown > div.dialog div.content > * {
    @apply mb-0;
  }

  div.dropdown > div.dialog div.content > * + * {
    @apply mt-3;
  }
</style>
