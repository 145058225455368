<script lang="ts">
  import { modalOpts } from "@src/stores";

  export let modalDiv: HTMLElement = null;
  
  let bodyValue: any;
  let isBody = false;

  $: isBody = !!$modalOpts?.body;
  $: bodyValue = undefined, isBody;

  const closeModal = (calledAction = false) => {
    if ($modalOpts.closeCallback) $modalOpts.closeCallback(calledAction);

    bodyValue = undefined;
    $modalOpts = null;
  };

  function clickOutside(node) {
    const isNestedChild = (parent, child) => {
      let currentNode = child;

      while (currentNode !== null) {
        if (currentNode.parentNode === parent) {
          return true;
        } else {
          currentNode = currentNode.parentNode;
        }
      }

      return false;
    };

    const handleClick = (event) => {
      if ($modalOpts) {
        const { target } = event;

        if (!node.isSameNode(target) && !isNestedChild(node, target) && !$modalOpts.exclusions?.includes(target.id)) {
          closeModal();
        }
      }
    };

    document.addEventListener("click", handleClick);

    return {
      destroy() {
        document.removeEventListener("click", handleClick);
      },
    };
  }
</script>

<div bind:this={modalDiv} class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center
  sm:justify-center {$modalOpts ? 'h-screen' : 'h-0'}" style="z-index: 1010;">
  <div class="fixed inset-0 transition-opacity {$modalOpts ? 'opacity-100 h-screen' : 'opacity-0 h-0'}">
    <div class="absolute inset-0 bg-gray-500 opacity-75" />
  </div>

  <div
    use:clickOutside
    class="relative bg-white dark:bg-gray-800  rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl
    transform transition-all sm:max-w-lg sm:w-full sm:p-6 {$modalOpts ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'}"
    style="z-index: 1020;"
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-headline">
    {#if $modalOpts}
      <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          on:click={() => closeModal()}
          class="text-gray-400 hover:text-gray-500 focus:outline-none
          focus:text-gray-500 transition ease-in-out duration-150"
          aria-label="Close">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12
          rounded-full bg-{$modalOpts.color}-100 sm:mx-0 sm:h-10 sm:w-10">
          {#if $modalOpts.icon == 'warn'}
            <svg class="h-6 w-6 text-{$modalOpts.color}-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667
                1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77
                1.333.192 3 1.732 3z" />
            </svg>
          {/if}
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100" id="modal-headline">{$modalOpts.title}</h3>
          <div class="mt-2">
            <p class="text-sm leading-5 text-gray-500 dark:text-gray-200">{$modalOpts.description}</p>
          </div>
        </div>
      </div>
      {#if $modalOpts.body}
        <div class="mt-5 sm:mt-4">
          <svelte:component this={$modalOpts.body} bind:value={bodyValue} {...$modalOpts.bodyOptions} />
        </div>
      {/if}
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        {#if $modalOpts.action}
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              on:click={() => {
                /* //TODO: RESET CACHE */ $modalOpts.action.callback(bodyValue);
                closeModal(true);
              }}
              class="inline-flex justify-center w-full rounded-md border
              border-transparent px-4 py-2 bg-{$modalOpts.color}-600 text-base leading-6
              font-medium text-white shadow-sm hover:bg-{$modalOpts.color}-500 focus:outline-none
              focus:border-{$modalOpts.color}-700 focus:shadow-outline-{$modalOpts.color} transition ease-in-out
              duration-150 sm:text-sm sm:leading-5">
              {$modalOpts.action.name}
            </button>
          </span>
        {/if}
        {#if !$modalOpts.hideCancel}
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              on:click={() => closeModal()}
              class="inline-flex justify-center w-full rounded-md border
              border-gray-300 px-4 py-2 bg-white dark:bg-gray-800  text-base leading-6 font-medium shadow-sm 
              text-gray-700 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-200 focus:outline-none
              focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out
              duration-150 sm:text-sm sm:leading-5">
              {$modalOpts.action ? 'Cancel' : 'Ok'}
            </button>
          </span>
        {/if}
      </div>
    {/if}
  </div>
</div>
