<script lang="ts">
  import { onMount } from "svelte";

  import QrCode from "svelte-qrcode";

  export let id: string, value: string, required: boolean, disabled: boolean;
  export let isUnsavedUpdates = false;
  export let roundRightonFocus = true;

  const checkIsDark = () => document.documentElement.classList.contains("dark");

  let isDark = checkIsDark();

  onMount(() => {
    const darkObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName == "class") {
          isDark = checkIsDark();
        }
      });
    });

    darkObserver.observe(document.documentElement, { attributes: true });

    return () => darkObserver.disconnect();
  });
</script>

<div class="flex flex-row border border-gray-500 dark:border-gray-200">
  {#if value}
    {#key isDark}
      <QrCode {value} className="w-full" size="170" padding="12" background={isDark ? '#2a2a31' : '#fff'} color={isDark ? '#fff' : '#000'} />
    {/key}
  {/if}
</div>
