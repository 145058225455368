import {
  textareaStyles,
  preStyles,
  rootStyles,
  subDivStyles,
} from "./styles.js";

class Yace {
  constructor(root, options = {}) {
    this.root = root;

    if (!this.root) {
      throw new Error(`Root element does not exist`);
    }

    const defaultOptions = {
      value: "",
      styles: {},
      plugins: [],
      highlighter: (value) => {
        return "<code>" + escape(value).replace(/\n/g, "</code>\n<code>") + "</code>";
      },
    };

    this.options = {
      ...defaultOptions,
      ...options,
    };

    this.init();
  }

  init() {
    this.subDiv = document.createElement("div");
    this.textarea = document.createElement("textarea");
    this.pre = document.createElement("pre");

    Object.assign(this.root.style, rootStyles);
    Object.assign(this.subDiv.style, subDivStyles);
    Object.assign(this.textarea.style, textareaStyles);
    Object.assign(this.pre.style, preStyles);

    this.root.appendChild(this.subDiv);
    this.subDiv.appendChild(this.textarea);
    this.subDiv.appendChild(this.pre);

    this.addTextareaEvents();
    this.update({ value: this.options.value });
  }

  addTextareaEvents() {
    this.handleInput = (event) => {
      const textareaProps = runPlugins(this.options.plugins, event);
      const abletouseshortcut = event.inputType == "insertLineBreak" ? false : true;
      this.update(textareaProps, abletouseshortcut);
    };

    this.handleKeydown = (event) => {
      const textareaProps = runPlugins(this.options.plugins, event);
      const abletouseshortcut = event.code == "Enter" ? false : true;
      this.update(textareaProps, abletouseshortcut);
    };

    this.textarea.addEventListener("input", this.handleInput);
    this.textarea.addEventListener("keydown", this.handleKeydown);
  }

  update(textareaProps, abletouseshortcut = false) {
    const { value, selectionStart, selectionEnd } = textareaProps;
    // should be before updating selection otherwise selection will be lost
    if (value != null) {
      this.textarea.value = value;
    }

    this.textarea.selectionStart = selectionStart;
    this.textarea.selectionEnd = selectionEnd;

    if (value === this.value || value == null) {
      return;
    }

    this.value = value;

    const newLinesB4Start = (this.value.substring(0, selectionStart).match(/\n/g) || []).length;
    const newLinesB4End = (this.value.substring(selectionEnd, this.value.length).match(/\n/g) || []).length;

    const totalNewLines = this.value.match(/\n/g).length;

    if (abletouseshortcut && selectionStart === selectionEnd && this.prevTotalNewLines === totalNewLines) { //Can do shortcut, if at same
      this.pre.children[newLinesB4Start].innerHTML = escape(findNthLine(this.value, newLinesB4Start));
    } else {
      const highlighted = this.options.highlighter(value);
      this.pre.innerHTML = highlighted + "<br/>";
    }

    this.prevTotalNewLines = totalNewLines;

    if (this.updateCallback) {
      this.updateCallback(value);
    }
  }

  destroy() {
    this.textarea.removeEventListener("input", this.handleInput);
    this.textarea.removeEventListener("keydown", this.handleKeydown);
  }

  onUpdate(callback) {
    this.updateCallback = callback;
  }
}

function runPlugins(plugins, event) {
  const { value, selectionStart, selectionEnd } = event.target;

  return plugins.reduce(
    (acc, plugin) => {
      return {
        ...acc,
        ...plugin(acc, event),
      };
    },
    { value, selectionStart, selectionEnd }
  );
}

function escape(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

function findNthLine(str, n) {
  return (new RegExp(`^(?:[^\n]*\n){${n}}([^\n]*)`, 's').exec(str))[1];
};

export default Yace;