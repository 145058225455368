<script lang="ts">
  enum TABS {
    ALL,
    NEURALMODELS,
    SELFTRAINING,
    FUNCTIONALINTELLIGENCE,
  }

  const setTab = (tab: TABS) => () => (currentTab = tab);

  let currentTab = TABS.ALL;

  export let options;

  const models: {
    icon: string;
    title: string;
    description: string;
    color: string;
  }[] = options.models ?? [];
</script>

<div class="databuilder-tabs">
  <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a tab</label>
    <select id="tabs" name="tabs" class="block w-full focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md">
      <option selected>All</option>
      <option>Neural Models</option>
      <option>Self Training</option>
      <option>Functional Intelligence</option>
    </select>
  </div>
  <div class="hidden sm:block">
    <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200 dark:divide-gray-400" aria-label="Tabs">
      <button
        on:click={setTab(TABS.ALL)}
        class:activeTab={currentTab == TABS.ALL}
        class:inactiveTab={currentTab != TABS.ALL}
        class=" rounded-l-lg focus:outline-none group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray-800 py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
      >
        <span>All</span>
        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5" />
      </button>

      <button
        on:click={setTab(TABS.NEURALMODELS)}
        class:activeTab={currentTab == TABS.NEURALMODELS}
        class:inactiveTab={currentTab != TABS.NEURALMODELS}
        class="focus:outline-none group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray-800 py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
      >
        <span>Neural Models</span>
        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5" />
      </button>

      <button
        on:click={setTab(TABS.SELFTRAINING)}
        class:activeTab={currentTab == TABS.SELFTRAINING}
        class:inactiveTab={currentTab != TABS.SELFTRAINING}
        class="focus:outline-none group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray-800 py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
      >
        <span>Self Training</span>
        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5" />
      </button>

      <button
        on:click={setTab(TABS.FUNCTIONALINTELLIGENCE)}
        class:activeTab={currentTab == TABS.FUNCTIONALINTELLIGENCE}
        class:inactiveTab={currentTab != TABS.FUNCTIONALINTELLIGENCE}
        class="rounded-r-lg focus:outline-none group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray-800 py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
      >
        <span>Functional Intelligence</span>
        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5" />
      </button>
    </nav>
  </div>
</div>

<div
  class="rounded-lg bg-gray-200 dark:bg-gray-400 overflow-hidden shadow divide-y divide-gray-200 dark:divide-gray-400 sm:divide-y-0 sm:grid sm:grid-cols-3 gap-px"
>
  {#each models as model}
    <div class="relative group bg-white dark:bg-gray-800 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-500">
      <div>
        <span class="rounded-lg inline-flex p-3 bg-{model.color}-50 ring-4 ring-white dark:ring-gray-800">
          <img class="h-6 w-6" src="/icons/{model.icon}.svg" alt="Icon" />
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <a href="/" on:click|preventDefault class="focus:outline-none">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true" />
            {model.title}
          </a>
        </h3>
        <p class="mt-2 text-sm text-gray-500 dark:text-gray-200">{model.description}</p>
      </div>
      <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path
            d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
          />
        </svg>
      </span>
    </div>
  {/each}
</div>

<style global>
  .databuilder\-tabs {
    @apply mb-6;
  }

  .databuilder\-tabs .activeTab {
    @apply text-gray-800;
  }

  .databuilder\-tabs .activeTab span[aria-hidden="true"] {
    @apply bg-primary-500;
  }

  .dark .databuilder\-tabs .activeTab {
    @apply text-white;
  }

  .databuilder\-tabs .inactiveTab {
    @apply text-gray-500;
  }

  .dark .databuilder\-tabs .inactiveTab {
    @apply text-gray-200;
  }

  .databuilder\-tabs .inactiveTab:hover {
    @apply text-gray-700;
  }

  .dark .databuilder\-tabs .inactiveTab:hover {
    @apply text-gray-300;
  }

  .databuilder\-tabs .inactiveTab span[aria-hidden="true"] {
    @apply bg-transparent;
  }
</style>
